import * as React from 'react';
import { SVGProps } from 'react';
const SvgCheckPinkEmpty = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.696 8.111c0 3.587-2.926 6.5-6.542 6.5-3.616 0-6.542-2.913-6.542-6.5s2.926-6.5 6.542-6.5c3.616 0 6.542 2.913 6.542 6.5Z"
      stroke="#B24ABF"
    />
  </svg>
);
export default SvgCheckPinkEmpty;
