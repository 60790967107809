import * as React from 'react';
import { SVGProps } from 'react';
const SvgArrowS = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.552 12.592a.665.665 0 0 0 .896 0l6.524-5.94a1.267 1.267 0 1 0-1.707-1.874L8 9.576 2.735 4.778A1.267 1.267 0 1 0 1.03 6.65l6.523 5.941Z"
      fill="#048727"
    />
  </svg>
);
export default SvgArrowS;
