import * as React from 'react';
import { SVGProps } from 'react';
const SvgCloseSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.859 6.45 8 6.59l.141-.14 3.601-3.601a.8.8 0 0 1 1.132 0l.277.277a.8.8 0 0 1 0 1.132l-3.6 3.6L9.409 8l.142.141 3.6 3.601a.8.8 0 0 1 0 1.132l-.277.277a.8.8 0 0 1-1.132 0l-3.6-3.6L8 9.409l-.141.142-3.601 3.6a.8.8 0 0 1-1.132 0l-.277-.277a.8.8 0 0 1 0-1.132l3.6-3.6L6.591 8l-.142-.141-3.6-3.601a.8.8 0 0 1 0-1.132l.277-.277a.8.8 0 0 1 1.132 0l3.6 3.6Z"
      fill="currentColor"
      stroke="#fff"
      strokeWidth={0.4}
    />
  </svg>
);
export default SvgCloseSmall;
